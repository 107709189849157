import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { ROUTES } from 'constants/routes';
import {
  OPERATIONAL_PERMISSIONS,
  GUEST_LIST_OPERATIONAL_PERMISSIONS,
  ALL_PERMISSIONS,
  INCOMING_CALL_PERMISSIONS,
} from 'constants/operational-permission';
import { FEATURES } from 'services/subscription';
import { selectHomeRoute, selectIsLoadingCurrentUser } from 'modules/Auth/selectors';
import { selectIsVenueLoading } from 'modules/Partner/selectors';
import Loader from 'components/ui/Loader';
import Feedback from 'pages/Feedback';
import Analytics from 'pages/Analytics';
import OnlineOrder from 'pages/OnlineOrder';
import Giftcards from 'pages/Giftcards';
import Settings from 'pages/Settings';
import WalkinFunnel from 'pages/WalkinFunnel';
import Calendar from 'pages/Calendar';
import ReservationFunnel from 'pages/ReservationFunnel';
import Reservations from 'pages/Reservations';
import Timeline from 'pages/Timeline';
import Guests from 'pages/Guests';
import PageNotFound from 'pages/PageNotFound';
import IncomingCall from 'pages/IncomingCall';
import NotificationsMobile from 'modules/NotificationCenter/components/NotificationsMobile';
import useServiceWorkerNavigation from 'hooks/useServiceWorkerNavigation';
import PrivateRoute from './PrivateRoute';

const PrivateRoutes = () => {
  const homeRoute = useAppSelector(selectHomeRoute);
  const isLoadingCurrentUser = useAppSelector(selectIsLoadingCurrentUser);
  const isVenueLoading = useAppSelector(selectIsVenueLoading);

  useServiceWorkerNavigation();

  if (isLoadingCurrentUser || isVenueLoading) {
    return <Loader.AppLoader />;
  }

  return (
    <Switch>
      <PrivateRoute
        exact
        path={ROUTES.HOME}
        // We are given all permission to the Home route because it is used just for redirection
        // and also private route redirect user to login page when not authenticated.
        allowedPermission={ALL_PERMISSIONS}
      >
        <Redirect to={homeRoute} />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.CALENDAR}
        feature={FEATURES.CALENDAR}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <Calendar />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.FEEDBACK_ANALYTICS}
        feature={FEATURES.FEEDBACK}
        allowedPermission={OPERATIONAL_PERMISSIONS.VIEW_FEEDBACK}
      >
        <Feedback />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.TRAFFIC_ANALYTICS}
        feature={FEATURES.ANALYTICS}
        allowedPermission={OPERATIONAL_PERMISSIONS.VIEW_ANALYTICS}
      >
        <Analytics />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.GIFTCARD_DASHBOARD}
        feature={FEATURES.GIFTCARD_DASHBOARD}
        allowedPermission={OPERATIONAL_PERMISSIONS.GIFT_CARD_OPERATIONS}
      >
        <Giftcards />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.SHOP_DASHBOARD}
        feature={FEATURES.DELIVERY_AND_TAKEAWAY}
        allowedPermission={OPERATIONAL_PERMISSIONS.TAKEAWAY_AND_DELIVERY_OPERATIONS}
        isHiddenOnMobile
      >
        <OnlineOrder />
      </PrivateRoute>
      <PrivateRoute path={ROUTES.SETTINGS} feature={FEATURES.SETTINGS}>
        <Settings />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.RESERVATION_FUNNEL}
        feature={FEATURES.RESERVATION_FUNNEL}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <ReservationFunnel />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.WALKIN_FUNNEL}
        feature={FEATURES.RESERVATION_FUNNEL}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <WalkinFunnel />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.FINISHED_RESERVATIONS}
        feature={FEATURES.RESERVATION_LISTING}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <Reservations />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.WAITLIST}
        feature={FEATURES.RESERVATION_LISTING}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <Reservations />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.QUEUE_LIST}
        feature={FEATURES.RESERVATION_LISTING}
        allowedPermission={OPERATIONAL_PERMISSIONS.QUEUE_OPERATIONS}
      >
        <Reservations />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.GUEST_LIST}
        feature={FEATURES.GUESTS}
        allowedPermission={GUEST_LIST_OPERATIONAL_PERMISSIONS}
      >
        <Guests />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.NOTIFICATIONS}
        feature={FEATURES.NOTIFICATIONS}
        allowedPermission={ALL_PERMISSIONS}
        isMobileOnly
      >
        <NotificationsMobile />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.TIMELINE}
        feature={FEATURES.TIMELINE}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <Timeline />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.RESERVATIONS}
        feature={FEATURES.RESERVATION_LISTING}
        allowedPermission={OPERATIONAL_PERMISSIONS.RESERVATION_OPERATIONS}
      >
        <Reservations />
      </PrivateRoute>
      <PrivateRoute
        path={ROUTES.INCOMING_CALL}
        feature={FEATURES.RESERVATION_LISTING}
        allowedPermission={INCOMING_CALL_PERMISSIONS}
      >
        <IncomingCall />
      </PrivateRoute>
      <PrivateRoute path="*" feature={FEATURES.PAGE_NOT_FOUND} allowedPermission={ALL_PERMISSIONS}>
        <PageNotFound />
      </PrivateRoute>
    </Switch>
  );
};

export default PrivateRoutes;
