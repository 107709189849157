/* eslint-disable no-console, camelcase */
import moment from 'moment-timezone';
import { initializeApp } from 'firebase/app';
import { getToken, deleteToken, isSupported, getMessaging, onMessage } from 'firebase/messaging';
import { getErrorMessage } from 'utils/errors';
import { showErrorMessage } from 'utils/alerts';
import { getPartnerSession } from 'utils/session';
import notificationApis from 'apis/notification';
import { LOCAL_STORAGE_KEY_DEVICE_TOKEN } from 'constants/app';

// [START initialize_firebase_in_sw]
initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});

const isTokenSentToServer = () => {
  try {
    const { currentVenueId } = getPartnerSession();
    const storedVenueId = +(
      JSON.parse(window?.localStorage?.getItem?.(LOCAL_STORAGE_KEY_DEVICE_TOKEN) || '{}')
        ?.venueId || 0
    );
    return (
      window?.localStorage?.getItem('sentDeviceTokenToServer') === '1' &&
      storedVenueId === Number(currentVenueId)
    );
  } catch (error) {
    console.error('Error parsing in function: isTokenSentToServer', error);
    return false;
  }
};

type TokenData = {
  token: string;
  venueId: number;
  timestamp: string;
};

const setTokenSentToServer = (sent: boolean, token?: TokenData) => {
  try {
    const { localStorage } = window;
    localStorage.setItem('sentDeviceTokenToServer', sent ? '1' : '0');
    if (sent) localStorage.setItem(LOCAL_STORAGE_KEY_DEVICE_TOKEN, JSON.stringify(token || ''));
  } catch (error) {
    console.error('Could not set token to localstorage in function: setTokenSentToServer', error);
  }
};

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
const sendTokenToServer = async (currentToken: string) => {
  if (!isTokenSentToServer()) {
    console.debug('Sending token to server...');
    try {
      const { currentVenueId } = getPartnerSession();
      const data = {
        token: currentToken,
        venueId: Number(currentVenueId),
        timestamp: moment().format(),
      };
      await notificationApis.saveDeviceTokenToServer(data);
      setTokenSentToServer(true, data);
    } catch (error) {
      console.error('Error sending the token to server');
      showErrorMessage(getErrorMessage(error));
      setTokenSentToServer(false);
    }
  } else {
    console.debug("Token already sent to server so won't send it again unless it changes");
  }
};

const requestPermission = () => {
  console.debug('Requesting permission...');
  // [START request_permission]
  if (window?.Notification?.requestPermission) {
    window.Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.debug('Notification permission granted.');
        const messaging = getMessaging();
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
          .then((currentToken) => {
            if (currentToken) {
              // Send the token to your server and update the UI if necessary
              sendTokenToServer(currentToken);
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
              setTokenSentToServer(false);
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenSentToServer(false);
          });
      } else {
        console.debug('Unable to get permission to notify.');
      }
    });
  }
  // [END request_permission]
};

const getFirebaseToken = () => {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  isSupported().then((isWindowSupported) => {
    const messaging = isWindowSupported ? getMessaging() : null;
    if (messaging) {
      getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            sendTokenToServer(currentToken);
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            setTokenSentToServer(false);
            requestPermission();
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          setTokenSentToServer(false);
        });
    }
  });
};

const deleteNotificationToken = () => {
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  isSupported().then((isWindowSupported) => {
    const messaging = isWindowSupported ? getMessaging() : null;
    if (messaging) {
      getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            window.localStorage.removeItem(LOCAL_STORAGE_KEY_DEVICE_TOKEN);
            deleteToken(messaging)
              .then(() => {
                console.debug('Token deleted.');
                setTokenSentToServer(false);
              })
              .catch((err) => {
                console.debug('Unable to delete token. ', err);
              });
          }
        })
        .catch((err) => {
          console.debug('Error retrieving Instance ID token. ', err);
        });
    }
  });
};

// Handle incoming order (Delivery/Pickup) messages when the app has focus
export function handleOrderNotifications() {
  isSupported().then((isWindowSupported) => {
    const messaging = isWindowSupported ? getMessaging() : null;
    if (messaging && 'Notification' in window) {
      onMessage(messaging, (payload) => {
        try {
          const { data } = JSON.parse(payload.data?.payload || '{}');

          // Only show notifications for pickup & delivery orders
          if (data.filter_key === 'delivery' || data.filter_key === 'takeaway') {
            const notification = new Notification(data.title, {
              body: data.body,
              icon: data.icon,
              tag: data.date || new Date().toString(),
              requireInteraction: true,
            });

            notification.onclick = () => {
              window.focus();
              notification.close();
            };
          }
        } catch (error) {
          console.error('Error parsing payload in handleOrderNotifications', error);
        }
      });
    }
  });
}

export { getFirebaseToken, deleteNotificationToken };
