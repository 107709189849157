import moment from 'moment-timezone';
import {
  WAITLIST_TIME_INTERVAL,
  ALL_DAY_AVAILABILITY,
  WAITLIST_STATUSES,
} from 'modules/ReservationsList/constants';
import { DATE_FORMAT } from 'constants/time-and-date';
import { FINISHED_WAITLIST_AVAILABILITY } from './constants';
import { checkStringMatch } from 'utils/string-helpers';
import { adjustDayAfterMidNight, formatDateToServerDate } from 'utils/date-and-time';
import { getReservationGuest, getReservationGuestName } from 'modules/ReservationsList/services';

// Returns date with minutes rounded to the next time interval
const defaultWaitlistStartTime = (selectedDate: any) => {
  const waitlistDate = moment(selectedDate, DATE_FORMAT).set({
    hour: moment().get('hour'),
    minute: moment().get('minute'),
  });

  const time = adjustDayAfterMidNight({
    time: waitlistDate,
  });

  const remainder = WAITLIST_TIME_INTERVAL - (time.minute() % WAITLIST_TIME_INTERVAL);
  return moment(time).add(remainder, 'minutes');
};

export const getWaitlistPopupTime = (time: any, selectedDate: any) => {
  if (time) {
    const mTime = moment(time);
    const waitlistDate = moment(selectedDate, DATE_FORMAT).set({
      hour: mTime.get('hour'),
      minute: mTime.get('minute'),
      second: 0,
      millisecond: 0,
    });

    // After midnight until 5:59 AM, we always retrieve the availability of the previous day to obtain slots that occur after midnight.
    // Therefore, we need to add a day to the selectedDate because the waitlist time needs to match the regular time
    // in order to correspond with the slot time that needs to be sent to the backend.

    return adjustDayAfterMidNight({
      time: waitlistDate,
    });
  }
  return defaultWaitlistStartTime(selectedDate);
};

const filterWaitlistBySearchText = (waitlistItemsSlots: any, searchText = '') => {
  const filteredItem = waitlistItemsSlots.map((availability: any) => ({
    ...availability,

    list: [
      ...availability.list
        // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        .sort((a: any, b: any) => new Date(a.startTime) - new Date(b.startTime))
        .filter((waitlistItem: any) => {
          const isStringMatched = checkStringMatch(
            [
              ...Object.values(getReservationGuest(waitlistItem)),
              getReservationGuestName(waitlistItem),
            ],
            searchText
          );
          return isStringMatched;
        }),
    ],
  }));

  return filteredItem;
};

export const applyWaitlistFilter = (
  waitlistItemsSlots: any,
  availabilityId: any,
  searchText: any
) => {
  const filterBySearchText = filterWaitlistBySearchText(waitlistItemsSlots, searchText);
  return filterBySearchText.filter(({ list, reservationAvailabilityId }: any) => {
    if (!list?.length) return false;
    if (availabilityId !== ALL_DAY_AVAILABILITY)
      return reservationAvailabilityId === availabilityId;
    return true;
  });
};

// get total number of items in waitlist, excluding finished (reservationAvailabilityId === null)
export const getWaitlistNotificationCount = ({ availabilities }: any = {}) =>
  Object.values(availabilities || {})?.reduce(
    // @ts-expect-error TS(2769): No overload matches this call.
    (total, { list, reservationAvailabilityId }) =>
      reservationAvailabilityId && reservationAvailabilityId !== FINISHED_WAITLIST_AVAILABILITY.id
        ? total + (list?.length || 0)
        : total,
    0
  );

export const getWaitlistCount = ({ waitlist }: any) =>
  // @ts-expect-error TS(2769): No overload matches this call.
  Object.values(waitlist || {})?.reduce((total, { list }) => total + (list?.length || 0), 0);

export const getWaitlistGuestCount = (lists: any) => {
  let count = 0;
  if (lists?.length) {
    const reducer = (accumulator: any, { list }: any) =>
      accumulator + list.reduce((acc: any, item: any) => acc + (item?.guestCount || 0), 0);
    count = lists.reduce(reducer, 0);
  }
  return count;
};

export const formatWaitlistTime = (startTime: any, endTime: any) =>
  moment(startTime).isSame(moment(endTime), 'minute')
    ? moment(startTime).format('LT')
    : `${moment(startTime).format('LT')} - ${moment(endTime).format('LT')}`;

export const getIsExpiredWaitlist = (status: any) =>
  status?.toUpperCase() === WAITLIST_STATUSES.EXPIRED_NOTIFY;

export const getWaitlistCacheKey = ({ venueId, date }: any) =>
  `${venueId}-${formatDateToServerDate(date)}`;
