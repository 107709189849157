import React from 'react';
import { COLORS } from 'constants/colors';
import { useIncomingCallActions } from 'modules/IncomingCall/hooks/useIncomingCallActions';
import type { IncomingCall } from 'modules/IncomingCall/types';
import AddReservationIcon from 'components/icons/AddReservationIcon';
import NotifyIcon from 'components/icons/statuses/NotifyIcon';
import styles from './Footer.module.scss';

type Props = {
  waitlist: Exclude<IncomingCall['waitlist'], null | undefined>;

  callback: () => void;
};

function WaitlistFooter({ waitlist, callback }: Props) {
  const { isFetchingWaitlist, handleAddReservationFromWaitlist, handleViewWaitlist } =
    useIncomingCallActions({
      waitlistId: waitlist.id,
      startTimeWithDate: waitlist.startTimeWithDate,
      callback,
    });

  return (
    <>
      <button
        type="button"
        className={styles.action}
        onClick={handleAddReservationFromWaitlist}
        aria-label="Add reservation"
        disabled={isFetchingWaitlist}
      >
        <AddReservationIcon color={COLORS.BLACK} />
        <span className={styles.label}>
          {isFetchingWaitlist ? 'Loading waitlist...' : 'Add reservation'}
        </span>
      </button>
      <button
        type="button"
        className={styles.action}
        onClick={handleViewWaitlist}
        aria-label="View waitlist"
        disabled={isFetchingWaitlist}
      >
        <NotifyIcon width={16} color={COLORS.BLACK} />
        <span className={styles.label}>View waitlist</span>
      </button>
    </>
  );
}

export default WaitlistFooter;
