import type { RootState } from 'redux-store';
import { RESERVATION_SPEND } from 'modules/ReservationsList/constants';
import { createSelector } from 'reselect';
import { PRODUCTS } from 'services/subscription';
import { COUNTRY_LANGUAGE } from '@umai/common';

export const selectCallingCode = ({ partner }: RootState) => partner.currentVenue.callingCode;

export const selectDefaultTurnTime = ({ partner }: RootState) =>
  partner.currentVenue.defaultTurnTime;

export const selectCurrentVenueId = ({ partner }: RootState) => partner.currentVenue.id;

export const selectCurrency = ({ partner }: RootState) => partner.currentVenue.currency;

export const selectProductSubscription = createSelector(
  ({ partner }: RootState) => partner.currentVenue.productSubscriptions,
  (productSubscriptions) => productSubscriptions || []
);

export const selectVenueCountryName = ({ partner }: RootState) =>
  partner.currentVenue?.country?.name;

export const selectVenueLanguage = createSelector(
  selectVenueCountryName,
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  (name) => COUNTRY_LANGUAGE[name?.toLowerCase()] || COUNTRY_LANGUAGE.default
);

export const selectIsLoyaltySubscribed = createSelector(
  selectProductSubscription,
  (productSubscriptions) => productSubscriptions.includes(PRODUCTS.LOYALTY)
);

export const selectIsGuestCenterSubscribed = createSelector(
  selectProductSubscription,
  (productSubscriptions) => productSubscriptions.includes(PRODUCTS.GUEST_CENTER)
);

export const selectIsQueueSubscribed = ({ partner }: RootState) =>
  partner.currentVenue?.isOfflineWaitlistEnabled;

export const selectIsShortTimeSubscribed = ({ partner }: RootState) =>
  partner.currentVenue?.isShortTurntimeEnabled;

export const selectLanguages = ({ partner }: RootState) => partner.currentVenue.languages;

export const selectIsVenueLoading = ({ partner }: RootState) => partner.venueLoading;

export const selectPartnerVenues = createSelector(
  ({ partner }: RootState) => partner.venues,
  (venues) => Object.values(venues)
);

export const selectTimeFormat = ({ partner }: RootState) => partner.currentVenue.timeFormat;

export const selectPartnerHasMultipleVenues = ({ partner }: RootState) =>
  partner.venueIds.length > 1;

export const selectCurrencyFormatter = ({ partner }: RootState) =>
  partner.currentVenue.currencyFormatter;

export const selectManualPaymentEnabled = ({ partner }: RootState) =>
  partner.currentVenue.isManualPaymentEnabled;

export const selectManualPaymentExpireHours = ({ partner }: RootState) =>
  partner.currentVenue.paymentExpireHours;

export const selectVenueGdprEnabled = ({ partner }: RootState) =>
  partner.currentVenue.address?.country?.gdprEnabled;

export const selectCurrentVenue = ({ partner }: RootState) => partner.currentVenue;

export const selectCurrentVenueLanguage = ({ partner }: RootState) =>
  partner?.currentVenue?.defaultLanguage || {};

export const selectVenueCommunication = createSelector(selectCurrentVenue, (currentVenue) => ({
  sms: !!currentVenue?.guestSmsEnabled,
  email: !!currentVenue?.guestEmailEnabled,
}));

export const selectVenueCommunicationEdit = createSelector(selectCurrentVenue, (currentVenue) => ({
  sms: !!currentVenue?.guestSmsReservationEditedEnabled,
  email: !!currentVenue?.guestEmailReservationEditedEnabled,
}));

export const selectRecordSpendSetting = createSelector(selectCurrentVenue, (currentVenue) => {
  const recordSpend = currentVenue.reservationSpend;
  return {
    optional: recordSpend === RESERVATION_SPEND.optional,
    mandatory: recordSpend === RESERVATION_SPEND.mandatory,
    off: recordSpend === RESERVATION_SPEND.noneSpend,
  };
});

export const selectVenueLoyaltyTierSystemEnabled = ({ partner }: RootState) =>
  partner.currentVenue.loyaltyTierSystemEnabled;

export const selectVenueCountryIsoCode = ({ partner }: RootState) =>
  partner.currentVenue.countryIsoCode;

export const selectCurrentVenueName = ({ partner }: RootState) => partner.currentVenue.name;

export const selectCurrentVenueInitials = ({ partner }: RootState) =>
  partner.currentVenue.nameInitials;

export const selectVenueDisplayColor = ({ partner }: RootState) =>
  partner.currentVenue.displayColor;

export const selectIsWhatsappEnabled = ({ partner }: RootState) =>
  partner.currentVenue.isWhatsappEnabled;

const selectPaymentProfiles = ({ partner }: RootState) => partner.paymentProfiles;

export const selectVenuePaymentProfiles = createSelector(
  [selectCurrentVenueId, selectPaymentProfiles],
  (venueId, profiles) => profiles[venueId]
);

/**
 * If there is payment profile for a venue and
 * 1. show warning is false - that is the venue is paying the subscription fees to umai or
 * 2. is access blocked - that is venue has not paid the subscription fees to umai and is blocked
 * 3. or if the venue has closed the warning by clicking close icon
 * 4. and there are warning present for the payment profile
 * Then don't show the warning/
 * */
export const selectIsVenueInPaymentWarning = createSelector(
  [selectVenuePaymentProfiles],
  (venuePaymentProfile) =>
    venuePaymentProfile &&
    venuePaymentProfile.showWarnings &&
    !venuePaymentProfile.isAccessBlocked &&
    venuePaymentProfile.isVisible &&
    !!venuePaymentProfile.warnings.length
);

const selectIsBlockedDueToPayment = ({ partner }: RootState) =>
  partner.currentVenue.isBlockedDueToPayment;

export const selectIsVenueAccessBlocked = createSelector(
  [selectVenuePaymentProfiles, selectIsBlockedDueToPayment],
  (venuePaymentProfile, isBlockedDueToPayment) =>
    venuePaymentProfile?.isAccessBlocked || isBlockedDueToPayment
);

export const selectPartnerId = ({ partner }: RootState) => partner.currentVenue.partner?.id;

export const selectReservationCustomFieldText = ({ partner }: RootState) =>
  partner.currentVenue.reservationCustomFieldText;

export const selectShowReservationCustomField = ({ partner }: RootState) =>
  partner.currentVenue.showReservationCustomField;

export const selectVenueIsFreemium = ({ partner }: RootState) => partner.currentVenue.isFreemium;

export const selectIsIncomingCallSubscribed = ({ partner }: RootState) =>
  partner.currentVenue.isCallFeatureSubscribed;

export const selectIsIncomingCallEnabled = ({ partner }: RootState) =>
  partner.currentVenue.isCallFeatureEnabled;

export const selectIsIncomingCallUnavailableModeEnabled = ({ partner }: RootState) =>
  partner.currentVenue.isCallFeatureUnavailableModeEnabled;

export const selectIsIncomingCallSensorType = ({ partner }: RootState) =>
  partner.currentVenue.popcallSensorType;

export const selectCurrentVenueWidgetUrl = ({ partner }: RootState) =>
  partner.currentVenue.widgetUrl;
